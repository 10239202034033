import {
  CAT_AGENDA_SOLICITA,
  SET_TIPO_DATA,
  SET_ESP_DATA,
  SET_DR_DATA,
  SET_CM_DATA,
  CAT_AGENDA_ERROR,
  SET_ESP_SELECIONADA,
  SET_CM_SELECCIONADO,
  SET_DR_SELECCIONADO,
  SET_HORARIOS_DATA,
  SET_HORARIO_SELECCIONADO,
  SET_ACCORDION_STATE,
  SET_HORARIO_SELECCIONADO_TEMPORAL,
  SET_MOSTRAR_CARGANDO,
  SET_OCULTAR_CARGANDO,
  SET_AUMENTAR_FILTRO,
  SET_ELIMINAR_FILTRO,
  SET_LIMPIAR_DATOS_AGENDA,
  SET_ERROR_AGENDA,
  RESET_COMBOS,
} from '../../actionTypes/ActionTypes';

const INIT_STATE = {
  tipo: '1',
  especialidades: [],
  especialidadSeleccionada: '',
  doctores: [],
  doctorSeleccionado: '',
  centro_medico: [],
  centro_medicoSeleccionado: '',
  horarios: [],
  horarioSeleccionado: [],
  horarioSeleccionadoTemporal: [],
  estaCargando: false,
  mensajeCargando: '',
  errorAgendas: false,
  expandirAgenda: true,
  filtrosSeleccionados: 0,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_COMBOS: {
      return { ...state, especialidadSeleccionada: '', doctorSeleccionado: '', centro_medicoSeleccionado: '' };
    }
    case CAT_AGENDA_SOLICITA: {
      return { ...state, estaCargando: true, errorAgendas: false };
    }
    case SET_TIPO_DATA: {
      return {
        ...state,
        tipo: action.payload,
        estaCargando: false,
        errorAgendas: false,
        especialidadSeleccionada: '',
        doctorSeleccionado: '',
        centro_medicoSeleccionado: '',
        horarios: [],
        horarioSeleccionado: [],
      };
    }
    case SET_ESP_DATA: {
      return { ...state, especialidades: action.payload, estaCargando: false, errorAgendas: false };
    }
    case SET_ESP_SELECIONADA: {
      return { ...state, especialidadSeleccionada: action.payload, estaCargando: false, errorAgendas: false };
    }
    case SET_DR_DATA: {
      return { ...state, doctores: action.payload, estaCargando: false, errorAgendas: false };
    }
    case SET_DR_SELECCIONADO: {
      return { ...state, doctorSeleccionado: action.payload, estaCargando: false, errorAgendas: false };
    }
    case SET_CM_DATA: {
      return { ...state, centro_medico: action.payload, estaCargando: false, errorAgendas: false };
    }
    case SET_CM_SELECCIONADO: {
      return { ...state, centro_medicoSeleccionado: action.payload, estaCargando: false, errorAgendas: false };
    }
    case SET_HORARIOS_DATA: {
      return { ...state, horarios: action.payload, estaCargando: false, errorAgendas: false };
    }
    case SET_HORARIO_SELECCIONADO: {
      return {
        ...state,
        horarioSeleccionado: action.payload,
        estaCargando: false,
        errorAgendas: false,
      };
    }
    case SET_HORARIO_SELECCIONADO_TEMPORAL: {
      return {
        ...state,
        horarioSeleccionadoTemporal: action.payload,
        estaCargando: false,
        errorAgendas: false,
      };
    }
    case CAT_AGENDA_ERROR: {
      return { ...state, mensajeError: action.payload, estaCargando: false, errorAgendas: true };
    }
    case SET_ERROR_AGENDA: {
      return { ...state, mensajeError: action.payload, estaCargando: false, errorAgendas: true };
    }
    case SET_ACCORDION_STATE: {
      return {
        ...state,
        expandirAgenda: action.payload,
      };
    }
    case SET_MOSTRAR_CARGANDO: {
      return {
        ...state,
        estaCargando: true,
        mensajeCargando: action.payload,
      };
    }
    case SET_OCULTAR_CARGANDO: {
      return {
        ...state,
        estaCargando: false,
        mensajeCargando: '',
      };
    }
    case SET_AUMENTAR_FILTRO: {
      return {
        ...state,
        filtrosSeleccionados:
          parseInt(state.filtrosSeleccionados, 10) === 3 ? 3 : parseInt(state.filtrosSeleccionados, 10) + 1,
      };
    }
    case SET_ELIMINAR_FILTRO: {
      return {
        ...state,
        estaCargando: false,
        filtrosSeleccionados:
          parseInt(state.filtrosSeleccionados, 10) === 0 ? 0 : parseInt(state.filtrosSeleccionados, 10) - 1,
      };
    }
    case SET_LIMPIAR_DATOS_AGENDA: {
      return { ...INIT_STATE };
    }
    default:
      return state;
  }
};
