import { SET_AUTORIZACION_HUMANA } from '../../actionTypes/ActionTypes';

const INIT_STATE = {
  autorizacionHumana: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_AUTORIZACION_HUMANA: {
      return { ...state, autorizacionHumana: action.payload };
    }
    /*  case SET_LIMPIAR_DATOS_ADICIONALES: {
      return { ...INIT_STATE };
    } */
    default:
      return state;
  }
};
