import React, { useContext } from 'react';
import FooterLogo from './FooterLogo';
import { Box, makeStyles } from '@material-ui/core';
import Hidden from '@material-ui/core/Hidden';
import AppContext from '../../contextProvider/AppContextProvider/AppContext';
import { THEME_TYPES } from '../../../constants/ThemeOptions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  vinculo: {
    color: '#000000',
    fontWeight: 'bold',
  },
}));

const Footer = props => {
  const classes = useStyles();
  const { themeType } = useContext(AppContext);

  return (
    <Box className={classes.root} {...props}>
      <Box display="flex" alignItems="center">
        <Hidden xsDown>
          <FooterLogo mr={5} color={themeType === THEME_TYPES.DARK ? 'white' : ''} />
        </Hidden>
        <Box fontSize={{ xs: 12, sm: 14 }} component="p" color="primary">
          Metrored | Centros Médicos &copy; {new Date().getFullYear()} v{process.env.REACT_APP_VERSION}
        </Box>
      </Box>
      {/* <Box fontSize={{ xs: 12, sm: 14 }} component="p" color="text.disabled">
        <a href="mailto:proyectos@metrored.med.ec" className={classes.vinculo}>
          ¿Se presento algún problema al utilizar la plataforma? Escríbenos para solucionarlo
        </a>
      </Box> */}
    </Box>
  );
};

export default Footer;
