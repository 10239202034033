import {
  SET_ASEGURADORA_DATA,
  CAT_ASEGURADORA_ERROR,
  CAT_ASEGURADORA_SELECCIONADA,
  SET_PLAN_DATA,
  CAT_PLANES_SOLICITA,
  CAT_PLANES_ERROR,
  CAT_PLAN_SELECCIONADO,
  CAT_DEPENDIENTE_SELECCIONADO,
  SET_LIMPIAR_DATOS_ASEGURADORA,
  SET_ASEGURADORA_RESTRICCION,
} from '../../actionTypes/ActionTypes';

const INIT_STATE = {
  cedulaTitular: '',
  aseguradoraData: [],
  aseguradoraSeleccionadaData: {
    idGema: '00',
    nombreAseguradora: 'SIN ASEGURADORA',
    base64: '',
  },
  planesData: [],
  planesSeleccionadosData: '',
  dependientesSeleccionadosData: '',
  estaCargando: false,
  error: false,
  errorPlanes: false,
  mensajeError: [],
  restriccionAseguradora: '', // SR, VD, PC, PA, BD
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_ASEGURADORA_DATA: {
      return { ...state, aseguradoraData: action.payload, error: false };
    }
    case CAT_ASEGURADORA_SELECCIONADA: {
      return { ...state, aseguradoraSeleccionadaData: action.payload, error: false };
    }
    case SET_ASEGURADORA_RESTRICCION: {
      return { ...state, restriccionAseguradora: action.payload, error: false };
    }
    case CAT_ASEGURADORA_ERROR: {
      return { ...state, aseguradoraData: [], error: true, estaCargando: false };
    }
    case CAT_PLANES_SOLICITA: {
      return { ...state, planesData: [], estaCargando: true, errorPlanes: false };
    }
    case SET_PLAN_DATA: {
      return { ...state, planesData: action.payload, estaCargando: false, cedulaTitular: action.cedulaTitular };
    }
    case CAT_PLAN_SELECCIONADO: {
      return { ...state, planesSeleccionadosData: action.payload, error: false };
    }
    case CAT_DEPENDIENTE_SELECCIONADO: {
      return { ...state, dependientesSeleccionadosData: action.payload, error: false };
    }
    case CAT_PLANES_ERROR: {
      return { ...state, mensajeError: action.payload, estaCargando: false, errorPlanes: true };
    }
    case SET_LIMPIAR_DATOS_ASEGURADORA: {
      return { ...INIT_STATE };
    }
    default:
      return state;
  }
};
