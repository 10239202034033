import React, { useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Dashboards from './Dashboards';
import Components from './Components';
import Apps from './Apps';
import Extensions from './Extensions';
import Charts from './Charts';
import Maps from './Maps';
import Widgets from './Widgets';
import Metrics from './Metrics';
import Login from './Auth/Login';
import Signup from './Auth/Register';
import ForgotPassword from './Auth/ForgotPassword';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ExtraPages from './ExtraPages';
import TourGuide from './TourGuide';
import CustomTimelines from './views/CustomTimelines';
import MaterialTimelines from './views/MaterialTimelines';
import Calendar from './Calendar';
import CRM from './CRM';
import TareasProgramadas from './TareasProgramadas';
import Agendamiento from './Agendamiento';
import { auth } from 'firebase';
import { URL_SECCIONES, URL_ROLES_SECCIONES } from '../config/api';
import axios from '../config/httpClient';
import Bienvenida from './Bienvenida';
import Pagos from './Pagos';
import Synlab from './Synlab';
import Vitality from './Vitality';
import Produbanco from './Produbanco';

const paginaPorDefecto = '/bienvenida/bienvenida';
const paginaInicioSesion = '/signin';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);

  //Obtengo el rol
  const { rol } = useSelector(({ auth }) => auth);

  //Primero filtro por secciones

  const [tienePermiso, setTienePermiso] = useState(null);
  useEffect(() => {
    const obtenerSecciones = async () => {
      setTienePermiso(null);
      //Obtengo las secciones
      const configGETSECCIONES = {
        method: 'get',
        url: URL_SECCIONES,
      };

      let secciones = await axios(configGETSECCIONES);
      secciones = secciones.data;

      let arregloSecciones = [];

      //**Agrego las secciones publicas
      for (let i = 0; i < secciones.length; i++) {
        const seccion = secciones[i];
        if (seccion.tipoDeAcceso === 'PUBLICO' && seccion.estado === 'ACT') {
          arregloSecciones.push(seccion);
        }
      }
      //**Agrego las secciones privadas relacionadas al rol

      // Obtengo todas las secciones privadas
      let arregloSeccionesPrivadas = [];
      for (let i = 0; i < secciones.length; i++) {
        const seccion = secciones[i];
        if (seccion.tipoDeAcceso === 'PRIVADO' && seccion.estado === 'ACT') {
          arregloSeccionesPrivadas.push(seccion);
        }
      }

      // Obtengo los roles secciones
      const configGETROLESSECCIONES = {
        method: 'get',
        url: URL_ROLES_SECCIONES,
      };

      let rolesSecciones = await axios(configGETROLESSECCIONES);
      rolesSecciones = rolesSecciones.data;

      // Obtengo todas las secciones permitidas para el rol actual
      let arregloRolSeccionDelRol = [];
      for (let i = 0; i < rolesSecciones.length; i++) {
        const rolSeccion = rolesSecciones[i];
        if (rolSeccion.id_rol === rol && rolSeccion.estado === 'ACT') {
          arregloRolSeccionDelRol.push(rolSeccion);
        }
      }

      // Comparo las secciones privadas que me envio el servidor
      // con las secciones permitidas para el rol actual
      // Si son iguales se muestran en pantalla
      for (let i = 0; i < arregloSeccionesPrivadas.length; i++) {
        const seccionPrivadaDesdeServidor = arregloSeccionesPrivadas[i];
        for (let j = 0; j < arregloRolSeccionDelRol.length; j++) {
          const rolSeccion = arregloRolSeccionDelRol[j];
          if (seccionPrivadaDesdeServidor.id_seccion === rolSeccion.id_seccion) {
            arregloSecciones.push(seccionPrivadaDesdeServidor);
          }
        }
      }

      //**Comparo con las secciones registradas en el menu con las secciones permitidas para el usuario

      let arregloURLPermitidas = [];
      for (let i = 0; i < arregloSecciones.length; i++) {
        const seccionPermitida = arregloSecciones[i];
        arregloURLPermitidas.push(seccionPermitida.url);
      }
      console.log('arregloURLPermitidas');
      console.log(arregloURLPermitidas);
      let seccionesPermitidas = arregloURLPermitidas;

      if (seccionesPermitidas.length !== 0) {
        let verificandoPermiso = false;
        for (let i = 0; i < seccionesPermitidas.length; i++) {
          const urlPermitida = seccionesPermitidas[i];
          if (urlPermitida === rest.location.pathname) {
            verificandoPermiso = true;
            break;
          }
        }
        setTienePermiso(verificandoPermiso);
      }
    };
    try {
      obtenerSecciones();
    } catch (error) {
      console.log(error.message);
    }
  }, [rol, rest.location.pathname]);

  return (
    <Route
      {...rest}
      render={props =>
        tienePermiso === null ? (
          <React.Fragment>
            <p>Cargando</p>
          </React.Fragment>
        ) : authUser && tienePermiso ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: paginaInicioSesion,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  console.log('****************************Location********************************');
  console.log(location);
  console.log(location.pathname);
  console.log(authUser);

  /**
   * Si es signup puede ingresar
   */

  if (location.pathname === paginaInicioSesion) {
    console.log('Pagina de inicio de sesion');
    if (authUser !== null) {
      console.log('Se envia a pagina por defecto');
      return <Redirect to={paginaPorDefecto} />;
    }
  } else {
    // Si es la pagina de password, tengo dos opciones o ya inicio sesion o no inicia sesion
    if (authUser === null) {
      console.log('No tiene acceso');
      return <Redirect to={paginaInicioSesion} />;
    }
    console.log('Tiene acceso, falta validar que el token puede acceder a la seccion');
  }
  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/crm" component={CRM} />
        <RestrictedRoute path="/synlab" component={Synlab} />
        <RestrictedRoute path="/vitality" component={Vitality} />
        <RestrictedRoute path="/produbanco" component={Produbanco} />
        <RestrictedRoute path="/agendamiento" component={Agendamiento} />
        <RestrictedRoute path="/tareasProgramadas" component={TareasProgramadas} />
        <RestrictedRoute path="/dashboard" component={Dashboards} />
        <Route path="/widgets" component={Widgets} />
        <Route path="/metrics" component={Metrics} />
        <Route path="/components" component={Components} />
        <Route path="/extensions" component={Extensions} />
        <Route path="/visualization/chart" component={Charts} />
        <Route path="/visualization/map" component={Maps} />
        <Route path="/extra-pages" component={ExtraPages} />
        <Route path="/apps" component={Apps} />
        <Route path="/custom-timeline" component={CustomTimelines} />
        <Route path="/material-timeline" component={MaterialTimelines} />
        <Route path="/calendar" component={Calendar} />
        <Route path="/signup" component={Signup} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/signin" component={Login} />
        <Route path="/bienvenida" component={Bienvenida} />
        <Route path="/" component={Bienvenida} />
        <Route path="/pagos" component={Pagos} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
