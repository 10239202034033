import { SET_COMENTARIO, SET_LIMPIAR_DATOS_ADICIONALES } from '../../actionTypes/ActionTypes';

const INIT_STATE = {
  comentario: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_COMENTARIO: {
      return { ...state, comentario: action.payload };
    }
    case SET_LIMPIAR_DATOS_ADICIONALES: {
      return { ...INIT_STATE };
    }
    default:
      return state;
  }
};
