import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import Common from './Common';
import Auth from './Auth';
import Dialogo from './Dialogo.reductor';
import DatosAgendamiento from './Agendamiento/DatosAgendamiento.reductor';
import DatosPaciente from './Agendamiento/DatosPaciente.reductor';
import DatosAseguradora from './Agendamiento/DatosAseguradora.reductor';
import DatosAgenda from './Agendamiento/DatosAgenda.reductor';
import DatosAdicionales from './Agendamiento/DatosAdicionales.reductor';
import DatosSynlab from './DatosSynlab.reductor';
import DatosCampanas from './DatosCampanas.reductor';
import DatosCampanasRELIV from './DatosCampanasRELIV.reductor';
import DatosTelemedicina from './TelemedicinaReporte/DatosTelemedicina.reductor';
import DatosVitality from './Vitality/DatosVitality.reductor';
import DatosAutorizacionHumana from './Agendamiento/DatosAutorizacionHumana.reductor';
import DatosProdubanco from './Produbanco/DatosProdubanco.reductor';

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    agendamiento: combineReducers({
      datosagendamiento: DatosAgendamiento,
      datospaciente: DatosPaciente,
      datosaseguradora: DatosAseguradora,
      datosagenda: DatosAgenda,
      datosadicionales: DatosAdicionales,
    }),
    dialogo: Dialogo,
    datossynlab: DatosSynlab,
    datoscampanas: DatosCampanas,
    datoscampanasreliv: DatosCampanasRELIV,
    telemedicina: DatosTelemedicina,
    vitality: DatosVitality,
    autHumana: DatosAutorizacionHumana,
    produbanco: DatosProdubanco,
  });
