import { MOSTRAR_DIALOGO, RESET_DIALOGO } from '../../@jumbo/constants/ActionTypes';

const initialState = {
  abiertoDialogo: false,
  tipo: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case MOSTRAR_DIALOGO:
      return {
        ...state,
        abiertoDialogo: !state.abiertoDialogo,
        tipo: action.payload,
      };
    case RESET_DIALOGO:
      return {
        ...state,
        abiertoDialogo: false,
        tipo: '',
      };

    default:
      return state;
  }
};
