import {
  CAT_ORDEN_SOLICITA,
  SET_ORDEN_DATA,
  CAT_ORDEN_ERROR,
  CAT_RESULTADOS_SOLICITA,
  SET_RESULTADOS_DATA,
  CAT_RESULTADOS_ERROR,
} from '../../@jumbo/constants/ActionTypes';

const initialState = {
  catOrdenes: [],
  ordenesCargando: false,
  ordenesError: false,
  catResultados: [],
  resultadosCargando: false,
  resultadosError: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CAT_ORDEN_SOLICITA:
      return {
        ...state,
        ordenesCargando: true,
        ordenesError: false,
      };
    case SET_ORDEN_DATA:
      return {
        ...state,
        catOrdenes: action.payload,
        ordenesCargando: false,
      };
    case CAT_ORDEN_ERROR:
      return {
        ...state,
        catOrdenes: [],
        ordenesCargando: false,
        ordenesError: true,
      };
    case CAT_RESULTADOS_SOLICITA:
      return {
        ...state,
        resultadosCargando: true,
        resultadosError: false,
      };
    case SET_RESULTADOS_DATA:
      return {
        ...state,
        catResultados: action.payload,
        resultadosCargando: false,
      };
    case CAT_RESULTADOS_ERROR:
      return {
        ...state,
        catResultados: [],
        resultadosCargando: false,
        resultadosError: true,
      };
    default:
      return state;
  }
};
