import {
  SET_PACIENTE_DATA,
  CAT_PACIENTE_ERROR,
  CAT_PACIENTE_SOLICITA,
  SET_LIMPIAR_DATOS_PACIENTE,
  CAT_PACIENTE_ES_NUEVO,
} from '../../actionTypes/ActionTypes';

const INIT_STATE = {
  pacienteData: [],
  cedula: '',
  pacienteCargado: false,
  pacienteError: false,
  esNuevo: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PACIENTE_DATA: {
      return { ...state, pacienteData: action.payload, pacienteCargado: false, pacienteError: false, cedula: '' };
    }
    case CAT_PACIENTE_ERROR: {
      return { ...state, pacienteData: [], pacienteCargado: false, pacienteError: true, cedula: action.payload };
    }
    case CAT_PACIENTE_ES_NUEVO: {
      return { ...state, esNuevo: action.payload };
    }
    case CAT_PACIENTE_SOLICITA: {
      return { ...state, pacienteCargado: true, pacienteError: false };
    }
    case SET_LIMPIAR_DATOS_PACIENTE: {
      return { ...INIT_STATE };
    }
    default:
      return state;
  }
};
