import {
  CAT_EXAMEN_SOLICITA,
  SET_EXAMEN_DATA,
  CAT_EXAMEN_ERROR,
  CAT_VITALITY_SOLICITA,
  SET_VITALITY_DATA,
  CAT_VITALITY_ERROR,
  CAT_RESULTADOS_SOLICITA,
  SET_RESULTADOS_DATA,
  CAT_RESULTADOS_ERROR,
  CAT_VITALITY_LIMPIAR,
  SET_ESTADO,
} from '../../actionTypes/ActionTypes';

const initialState = {
  catExamenVitality: [],
  catExamenVitalityCargando: false,
  catExamenVitalityError: false,
  catPacienteVitality: [],
  catPacienteVitalityCargando: false,
  catPacienteVitalityError: false,
  catResultados: [],
  resultadosCargando: false,
  resultadosError: false,
  estado: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CAT_VITALITY_SOLICITA:
      return {
        ...state,
        catPacienteVitalityCargando: true,
        catPacienteVitalityError: false,
      };
    case SET_VITALITY_DATA:
      return {
        ...state,
        catPacienteVitality: action.payload,
        catPacienteVitalityCargando: false,
      };
    case CAT_VITALITY_ERROR:
      return {
        ...state,
        catPacienteVitality: [],
        catPacienteVitalityCargando: false,
        catPacienteVitalityError: true,
      };
    case CAT_EXAMEN_SOLICITA:
      return {
        ...state,
        catExamenVitalityCargando: true,
        catExamenVitalityError: false,
      };
    case SET_EXAMEN_DATA:
      return {
        ...state,
        catExamenVitality: action.payload,
        catExamenVitalityCargando: false,
      };
    case CAT_EXAMEN_ERROR:
      return {
        ...state,
        catExamenVitality: [],
        catExamenVitalityCargando: false,
        catExamenVitalityError: true,
      };

    case CAT_RESULTADOS_SOLICITA:
      return {
        ...state,
        resultadosCargando: true,
        resultadosError: false,
      };
    case SET_RESULTADOS_DATA:
      return {
        ...state,
        catResultados: action.payload,
        resultadosCargando: false,
        estado: action.estado,
      };
    case CAT_RESULTADOS_ERROR:
      return {
        ...state,
        catResultados: [],
        resultadosCargando: false,
        resultadosError: true,
      };
    case SET_ESTADO:
      return {
        ...state,
        estado: action.payload,
      };
    case CAT_VITALITY_LIMPIAR:
      return initialState;
    default:
      return state;
  }
};
