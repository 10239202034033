import React, { useEffect, useState } from 'react';

import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import SidebarButtons from './SIdebarButtons';
import { sidebarNavs } from '../menus';
import { useSelector } from 'react-redux';
import { URL_SECCIONES, URL_ROLES_SECCIONES } from '../../../../../config/api';
import axios from '../../../../../config/httpClient';

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: 'calc(100% - 167px)',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: 'calc(100% - 167px)',
    },
  },
}));

const SideBar = () => {
  const classes = useStyles();
  //Obtengo el rol
  const { rol } = useSelector(({ auth }) => auth);
  console.log('Ingreso en menu sidebar');
  console.log(rol);

  console.log('sidebarNavs');
  console.log(sidebarNavs);

  //Primero filtro por secciones

  const [secciones, setSecciones] = useState([]);

  useEffect(() => {
    const llamada = async () => {
      try {
        const configGET = {
          method: 'get',
          url: URL_SECCIONES,
        };

        const response = await axios(configGET);

        setSecciones(response.data);
      } catch (error) {
        console.log(error);

        setSecciones([]);
      }
    };
    llamada();
  }, []);

  const [rolesSecciones, setRolesSecciones] = useState([]);

  useEffect(() => {
    const llamada = async () => {
      try {
        const configGET = {
          method: 'get',
          url: URL_ROLES_SECCIONES,
        };

        const response = await axios(configGET);

        setRolesSecciones(response.data);
      } catch (error) {
        console.log(error);

        setRolesSecciones([]);
      }
    };
    llamada();
  }, []);

  const [seccionesPermitidas, setSeccionesPermitidas] = useState([]);
  useEffect(() => {
    let arregloSecciones = [];

    //**Agrego las secciones publicas
    for (let i = 0; i < secciones.length; i++) {
      const seccion = secciones[i];
      if (seccion.tipoDeAcceso === 'PUBLICO' && seccion.estado === 'ACT') {
        arregloSecciones.push(seccion);
      }
    }
    //**Agrego las secciones privadas relacionadas al rol

    // Obtengo todas las secciones privadas
    let arregloSeccionesPrivadas = [];
    for (let i = 0; i < secciones.length; i++) {
      const seccion = secciones[i];
      if (seccion.tipoDeAcceso === 'PRIVADO' && seccion.estado === 'ACT') {
        arregloSeccionesPrivadas.push(seccion);
      }
    }

    // Obtengo todas las secciones permitidas para el rol actual
    let arregloRolSeccionDelRol = [];
    for (let i = 0; i < rolesSecciones.length; i++) {
      const rolSeccion = rolesSecciones[i];
      if (rolSeccion.id_rol === rol && rolSeccion.estado === 'ACT') {
        arregloRolSeccionDelRol.push(rolSeccion);
      }
    }

    // Comparo las secciones privadas que me envio el servidor
    // con las secciones permitidas para el rol actual
    // Si son iguales se muestran en pantalla
    for (let i = 0; i < arregloSeccionesPrivadas.length; i++) {
      const seccionPrivadaDesdeServidor = arregloSeccionesPrivadas[i];
      for (let j = 0; j < arregloRolSeccionDelRol.length; j++) {
        const rolSeccion = arregloRolSeccionDelRol[j];
        if (seccionPrivadaDesdeServidor.id_seccion === rolSeccion.id_seccion) {
          arregloSecciones.push(seccionPrivadaDesdeServidor);
        }
      }
    }

    //**Comparo con las secciones registradas en el menu con las secciones permitidas para el usuario

    let arregloSeccionesPermitidas = [];
    console.log('***********************secciones desde el servidor**********************');
    console.log(arregloSecciones);

    let arregloPadres = [];
    for (let i = 0; i < sidebarNavs.length; i++) {
      const seccionDesdeMenu = sidebarNavs[i];

      let arregloHijos = [];

      for (let k = 0; k < seccionDesdeMenu.children.length; k++) {
        const hijoMenu = seccionDesdeMenu.children[k];

        // Comparo el elemento hijo del menu con las secciones permitidas para el rol
        for (let m = 0; m < arregloSecciones.length; m++) {
          const seccionPermitidaServidor = arregloSecciones[m];
          if (hijoMenu.link === seccionPermitidaServidor.url) {
            arregloHijos.push(hijoMenu);
          }
        }
      }

      // Si no tiene
      if (arregloHijos.length !== 0) {
        arregloPadres.push({
          children: arregloHijos,
          name: seccionDesdeMenu.name,
          type: seccionDesdeMenu.type,
        });
      }
    }
    setSeccionesPermitidas(arregloPadres);
  }, [secciones, rolesSecciones]);

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      <CmtVertical menuItems={seccionesPermitidas} />
      {/* <SidebarButtons /> */}
    </PerfectScrollbar>
  );
};

export default SideBar;
