import {
  CAT_TELEMEDICINA_SOLICITA,
  SET_TELEMEDICINA_DATA,
  SET_FILTROS_TELEMEDICINA,
  CAT_TELEMEDICINA_ERROR,
  SET_LIMPIAR_TELEMEDICINA,
} from '../../actionTypes/ActionTypes';

const INIT_STATE = {
  telemedicinaData: [],
  cedula: '',
  telemedicinaDataCargado: false,
  telemedicinaDataError: false,
  tipo: '',
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CAT_TELEMEDICINA_SOLICITA: {
      return { ...state, telemedicinaDataCargado: true, telemedicinaDataError: false };
    }
    case SET_TELEMEDICINA_DATA: {
      return {
        ...state,
        telemedicinaData: action.payload,
        telemedicinaDataCargado: false,
        telemedicinaDataError: false,
        tipo: action.tipo,
      };
    }
    case CAT_TELEMEDICINA_ERROR: {
      return { ...state, pacienteData: [], telemedicinaDataCargado: false, telemedicinaDataError: true };
    }
    case SET_LIMPIAR_TELEMEDICINA: {
      return { ...INIT_STATE };
    }
    default:
      return state;
  }
};
