import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';

const Synlab = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/synlab`} />
        {/* <Route path={`${requestedUrl}/resultados`} component={lazy(() => import('./ListadoResultados'))} /> */}
        <Route path={`${requestedUrl}/ordenes`} component={lazy(() => import('./ListadoOrdenes'))} />
      </Switch>
    </Suspense>
  );
};

export default Synlab;
