import { fetchError, fetchStart, fetchSuccess } from '../../../redux/actions';
import {
  setAuthUser,
  setForgetPassMailSent,
  updateLoadUser,
  almacenarRol,
  almacenarEmpresa,
} from '../../../redux/actions/Auth';
import React from 'react';
import axios from '../../../config/httpClient';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
import { URL_ENCONTRAR_ROL } from '../../../config/api';

const JWTAuth = {
  onRegister: ({ name, email, password }) => {
    return dispatch => {
      dispatch(fetchStart());
      axios
        .post('auth/register', {
          email: email,
          password: password,
          name: name,
        })
        .then(({ data }) => {
          if (data.result) {
            localStorage.setItem('token', data.token.access_token);
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + data.token.access_token;
            dispatch(fetchSuccess());
            dispatch(JWTAuth.getAuthUser(true, data.token.access_token));
          } else {
            dispatch(fetchError(data.error));
          }
        })
        .catch(function(error) {
          dispatch(fetchError(error.message));
        });
    };
  },

  onLogin: ({ email, password }) => {
    return dispatch => {
      try {
        dispatch(fetchStart());
        axios
          .post(
            '/api/auth/login',
            {
              username: email,
              password: password,
            },
            {
              headers: {
                'Access-Control-Allow-Origin': '*',
              },
            },
          )
          .then(({ data }) => {
            let token = data.token;
            let Exp_time = data.Exp_time;
            if (token) {
              localStorage.setItem('token', token);
              localStorage.setItem('Exp_time', Exp_time);
              axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
              dispatch(fetchSuccess());
              dispatch(JWTAuth.getAuthUser(true, token));
            } else {
              dispatch(fetchError(data.error));
            }
          })
          .catch(function(error) {
            dispatch(fetchError(error.message));
          });
      } catch (error) {
        dispatch(fetchError(error.message));
      }
    };
  },
  onLogout: () => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(fetchSuccess());
      localStorage.removeItem('token');
      dispatch(setAuthUser(null));

      // axios
      //   .post('auth/logout')
      //   .then(({ data }) => {
      //     if (data.result) {
      //       dispatch(fetchSuccess());
      //       localStorage.removeItem('token');
      //       dispatch(setAuthUser(null));
      //     } else {
      //       dispatch(fetchError(data.error));
      //     }
      //   })
      //   .catch(function(error) {
      //     dispatch(fetchError(error.message));
      //   });
    };
  },

  getAuthUser: (loaded = false, token) => {
    return dispatch => {
      dispatch(fetchStart());
      dispatch(updateLoadUser(loaded));

      setTimeout(() => {
        const Exp_time = localStorage.getItem('Exp_time');
        if (Exp_time !== null) {
          if (moment(Exp_time) <= moment()) {
            //Ya caduco debo iniciar sesion
            dispatch(fetchStart());
            dispatch(fetchSuccess());
            localStorage.removeItem('token');
            dispatch(setAuthUser(null));
          }
        } else {
          //No tengo almacenado debo iniciar sesion
          dispatch(fetchStart());
          dispatch(fetchSuccess());
          localStorage.removeItem('token');
          dispatch(setAuthUser(null));
        }
        try {
          const token = localStorage.getItem('token');

          if (token !== null) {
            axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
            const decoded = jwt_decode(token);
            const usuario = decoded.sub;
            //En base al usuario obtengo el rol con la tabla usuarios
            const configGET = {
              method: 'get',
              url: URL_ENCONTRAR_ROL,
              params: {
                NOMBRE_USUARIO: usuario,
              },
            };

            console.log(configGET);
            axios(configGET).then(recibo => {
              console.log('response roles');
              console.log(recibo);
              let rol = recibo.data[0].id_rol;
              console.log('*****************************************************idEmpresa', idEmpresa);
              let idEmpresa = recibo.data[0].idEmpresa;
              console.log('*****************************************************idEmpresa', idEmpresa);
              console.log('*****************************************************recibo.data[0]', recibo.data[0]);
              console.log(rol);
              //Almaceno el rol para el resto de la app
              dispatch(almacenarRol(rol));
              dispatch(almacenarEmpresa(idEmpresa));
              dispatch(fetchSuccess());
              dispatch(setAuthUser(usuario));
            });
          } else {
            //No tengo almacenado el token debo iniciar sesion
            dispatch(fetchStart());
            dispatch(fetchSuccess());
            localStorage.removeItem('token');
            dispatch(setAuthUser(null));
          }
        } catch (error) {
          //Ocurrio un error debo iniciar sesion
          dispatch(fetchStart());
          dispatch(fetchSuccess());
          localStorage.removeItem('token');
          dispatch(setAuthUser(null));
        }
      }, 300);
    };
  },

  onForgotPassword: () => {
    return dispatch => {
      dispatch(fetchStart());

      setTimeout(() => {
        dispatch(setForgetPassMailSent(true));
        dispatch(fetchSuccess());
      }, 300);
    };
  },
  getSocialMediaIcons: () => {
    return <React.Fragment> </React.Fragment>;
  },
};

export default JWTAuth;
