import React, { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import PageLoader from '../../@jumbo/components/PageComponents/PageLoader';

const CRM = ({ match }) => {
  const requestedUrl = match.url.replace(/\/$/, '');
  return (
    <Suspense fallback={<PageLoader />}>
      <Switch>
        <Redirect exact from={`${requestedUrl}/`} to={`${requestedUrl}/reportesCitas`} />
        {/* <Route path={`${requestedUrl}/reportesCitas`} component={lazy(() => import('./ReportesCitas'))} /> */}
        <Route path={`${requestedUrl}/reportesCitasV2`} component={lazy(() => import('./ReporteCitasV2'))} />
        <Route path={`${requestedUrl}/mensajesAutomaticos`} component={lazy(() => import('./MensajesAutomaticos'))} />
        <Route path={`${requestedUrl}/mensajesAutomaticosV2`} component={lazy(() => import('./MensajesAutomaticosV2'))} />
        <Route path={`${requestedUrl}/reporte`} component={lazy(() => import('./Telemedicina'))} />
        <Route path={`${requestedUrl}/CampanaRELIV`} component={lazy(() => import('./CampanaRELIV'))} />
      </Switch>
    </Suspense>
  );
};

export default CRM;
