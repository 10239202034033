import {
  CAT_PRODUBANCO_SOLICITA,
  SET_PRODUBANCO_DATA,
  CAT_PRODUBANCO_ERROR,
  CAT_RESULTADOS_PRODU_SOLICITA,
  SET_RESULTADOS_PRODU_DATA,
  CAT_RESULTADOS_PRODU_ERROR,
  SET_ESTADO_PRODUBANCO,
  CAT_PRODUBANCO_LIMPIAR,
} from '../../actionTypes/ActionTypes';

const initialState = {
  catPacienteProdubanco: [],
  catPacienteProdubancoCargando: false,
  catPacienteProdubancoError: false,
  catResultadosProdubanco: [],
  resultadosProdubancoCargando: false,
  resultadosProdubancoError: false,
  estadoProdubanco: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CAT_PRODUBANCO_SOLICITA:
      return {
        ...state,
        catPacienteProdubancoCargando: true,
        catPacienteProdubancoError: false,
      };
    case SET_PRODUBANCO_DATA:
      return {
        ...state,
        catPacienteProdubanco: action.payload,
        catPacienteProdubancoCargando: false,
      };
    case CAT_PRODUBANCO_ERROR:
      return {
        ...state,
        catPacienteProdubanco: [],
        catPacienteProdubancoCargando: false,
        catPacienteProdubancoError: true,
      };
    case CAT_RESULTADOS_PRODU_SOLICITA:
      return {
        ...state,
        resultadosProdubancoCargando: true,
        resultadosProdubancoError: false,
      };
    case SET_RESULTADOS_PRODU_DATA:
      return {
        ...state,
        catResultadosProdubanco: action.payload,
        resultadosProdubancoCargando: false,
        estadoProdubanco: action.estado,
      };
    case CAT_RESULTADOS_PRODU_ERROR:
      return {
        ...state,
        catResultadosProdubanco: [],
        resultadosProdubancoCargando: false,
        resultadosProdubancoError: true,
      };
    case SET_ESTADO_PRODUBANCO:
      return {
        ...state,
        estadoProdubanco: action.payload,
      };
    case CAT_PRODUBANCO_LIMPIAR:
      return initialState;
    default:
      return state;
  }
};
