import {
  SET_CAMPANA_DATA,
  CAT_CAMPANA_ERROR,
  CAT_CAMPANA_SOLICITA,
  SET_DOCTORES_DATA,
  SET_ESPECIALIDADES_DATA,
  SET_CENTRO_MEDICO_DATA,
  SET_ASEGURADORAS_DATA,
} from '../../@jumbo/constants/ActionTypes';

const INIT_STATE = {
  campanaData: [],
  campanaCargado: false,
  campanaError: false,
  columnas: [
    { key: 1, title: `{{IDENTIFICACION}}` },
    { key: 2, title: '{{PNOMBRE_PACIENTE}}' },
    { key: 3, title: '{{SNOMBRE_PACIENTE}}' },
    { key: 4, title: '{{PAPELLIDO_PACIENTE}}' },
    { key: 5, title: '{{SAPELLIDO_PACIENTE}}' },
    { key: 6, title: '{{DENOMINACION_PACIENTE}}' },
    { key: 7, title: '{{ARTICULO_PACIENTE}}' },
    { key: 8, title: '{{SEXO_PACIENTE}}' },
    { key: 9, title: '{{FECHA_NACIMIENTO}}' },
    { key: 10, title: '{{EDAD}}' },
    { key: 11, title: '{{EMAIL}}' },
    { key: 12, title: '{{CELULAR}}' },
    { key: 13, title: '{{ESPECIALIDAD}}' },
    { key: 14, title: '{{ARTICULO_DOCTOR}}' },
    { key: 15, title: '{{NOMBRE_DOCTOR}}' },
    { key: 16, title: '{{APELLIDO_DOCTOR}}' },
    { key: 17, title: '{{LINK_GPS}}' },
    { key: 18, title: '{{FECHA_ATENCION}}' },
    { key: 19, title: '{{TIPO_HORARIO}}' },
    { key: 20, title: '{{HORARIO}}' },
    { key: 21, title: '{{NUMTURNO}}' },
    { key: 22, title: '{{DIAS_ESPERA}}' },
    { key: 23, title: '{{ASISTENCIA}}' },
    { key: 24, title: '{{ASEGURADORA}}' },
  ],
  doctores: [],
  especialidades: [],
  centrosmedicos: [],
  aseguradoras: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case CAT_CAMPANA_SOLICITA: {
      return { ...state, campanaCargado: true, campanaError: false };
    }
    case SET_CAMPANA_DATA: {
      return {
        ...state,
        campanaData: action.payload,
        campanaCargado: false,
        campanaError: false,
      };
    }
    case SET_DOCTORES_DATA: {
      return {
        ...state,
        doctores: action.payload,
      };
    }
    case SET_ESPECIALIDADES_DATA: {
      return {
        ...state,
        especialidades: action.payload,
      };
    }
    case SET_CENTRO_MEDICO_DATA: {
      return {
        ...state,
        centrosmedicos: action.payload,
      };
    }
    case SET_ASEGURADORAS_DATA: {
      return {
        ...state,
        aseguradoras: action.payload,
      };
    }
    case CAT_CAMPANA_ERROR: {
      return { ...state, campanaData: [], campanaCargado: false, campanaError: true };
    }
    default:
      return state;
  }
};
