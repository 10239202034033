import React from 'react';
import Hidden from '@material-ui/core/Hidden';
import { Box } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import CmtImage from '../../../../@coremat/CmtImage';
import { useSelector } from 'react-redux';

const Logo = ({ color, ...props }) => {
  const logoUrl = color === 'white' ? '/images/logo-white.png' : '/images/logo.png';
  const logoHumana = '/images/logoHumana.png';
  const logoSaludsa = '/images/logoSaludsa.png';
  const logoAON = '/images/logoAON.png';
  const logoEcuaprimas = '/images/logoEcuaprimas.png';
  const logoTecniseguros = '/images/logoTecniseguros.png';
  const logoAsertec = '/images/logoAsertec.png';
  const logoRSA = '/images/logoRSA.png';
  const logoMultiapoyo = '/images/logoMultiapoyo.png';
  const logoZHM = '/images/logoZHM.png';
  const logoCidescol = '/images/logoCidescol.webp';
  const logoServiseguros = '/images/logoServiseguros.svg';

  const logoSymbolUrl = color === 'white' ? '/images/logo-white-symbol.png' : '/images/logo-symbol.png';
  const { idEmpresa } = useSelector(({ auth }) => auth);
  return (
    <Box className="pointer" {...props}>
      <Hidden xsDown>
        <NavLink to="/">
          <CmtImage src={logoUrl} alt="logo" />
          {/*           {parseInt(idEmpresa) === parseInt(process.env.REACT_APP_IDEMPRESA_SERVIALAMO) ? (
            <CmtImage style={{ maxWidth: '130px', marginLeft: '30px' }} src={logoSaludsa} alt="Logo Salud" />
          ) : null} */}

          {parseInt(idEmpresa) === parseInt(process.env.REACT_APP_IDEMPRESA_METRORED) ? null : parseInt(idEmpresa) ===
            parseInt(process.env.REACT_APP_IDEMPRESA_HUMANA) ? (
            <CmtImage style={{ maxWidth: '130px', marginLeft: '30px' }} src={logoHumana} alt="Logo Humana" />
          ) : parseInt(idEmpresa) === parseInt(process.env.REACT_APP_IDEMPRESA_SALUDSA) ? (
            <CmtImage style={{ maxWidth: '130px', marginLeft: '30px' }} src={logoSaludsa} alt="Logo Salud" />
          ) : parseInt(idEmpresa) === parseInt(process.env.REACT_APP_IDEMPRESA_SERVIMAX) ? (
            <CmtImage style={{ maxWidth: '130px', marginLeft: '30px' }} src={logoSaludsa} alt="Logo Salud" />
          ) : parseInt(idEmpresa) === parseInt(process.env.REACT_APP_IDEMPRESA_SERVIALAMO) ? (
            <CmtImage style={{ maxWidth: '130px', marginLeft: '30px' }} src={logoSaludsa} alt="Logo Salud" />
          ) : parseInt(idEmpresa) === parseInt(process.env.REACT_APP_IDEMPRESA_AON) ? (
            <CmtImage style={{ maxWidth: '130px', marginLeft: '30px' }} src={logoAON} alt="Logo AON" />
          ) : parseInt(idEmpresa) === parseInt(process.env.REACT_APP_IDEMPRESA_ECUAPRIMAS) ? (
            <CmtImage style={{ maxWidth: '130px', marginLeft: '30px' }} src={logoEcuaprimas} alt="Logo Ecuaprimas" />
          ) : parseInt(idEmpresa) === parseInt(process.env.REACT_APP_IDEMPRESA_TECNISEGUROS) ? (
            <CmtImage style={{ maxWidth: '130px', marginLeft: '30px' }} src={logoTecniseguros} alt="Logo Tecniseguros" />
          ) : parseInt(idEmpresa) === parseInt(process.env.REACT_APP_IDEMPRESA_ASERTEC) ? (
            <CmtImage
              style={{ maxWidth: '130px', marginLeft: '30px', maxHeight: '60px', width: '110px' }}
              src={logoAsertec}
              alt="Logo Asertec"
            />
          ) : parseInt(idEmpresa) === parseInt(process.env.REACT_APP_IDEMPRESA_CIDESCOL) ? (
            <CmtImage
              style={{ maxWidth: '130px', marginLeft: '30px', maxHeight: '60px', width: '110px' }}
              src={logoCidescol}
              alt="Logo Cidescol"
            />
          ) : parseInt(idEmpresa) === parseInt(process.env.REACT_APP_IDEMPRESA_SERVISEGUROS) ? (
            <CmtImage
              style={{ maxWidth: '130px', marginLeft: '30px', maxHeight: '60px', width: '110px' }}
              src={logoServiseguros}
              alt="Logo Serviseguros"
            />
          ) : parseInt(idEmpresa) === parseInt(process.env.REACT_APP_IDEMPRESA_RSA) ? (
            <CmtImage style={{ maxWidth: '130px', marginLeft: '30px' }} src={logoRSA} alt="Logo RSA" />
          ) : parseInt(idEmpresa) === parseInt(process.env.REACT_APP_IDEMPRESA_MULTIAPOYO) ? (
            <CmtImage style={{ maxWidth: '130px', marginLeft: '30px' }} src={logoMultiapoyo} alt="Logo Multiapoyo" />
          ) : parseInt(idEmpresa) === parseInt(process.env.REACT_APP_IDEMPRESA_ZHM) ? (
            <CmtImage style={{ maxWidth: '130px', marginLeft: '30px', maxHeight: '54px' }} src={logoZHM} alt="Logo ZHM" />
          ) : null}
        </NavLink>
      </Hidden>
      <Hidden smUp>
        <NavLink to="/">
          <CmtImage src={logoSymbolUrl} alt="logo" />
        </NavLink>
      </Hidden>
    </Box>
  );
};

export default Logo;
