//DATOS PACIENTE
export const SET_PACIENTE_DATA = '1/PACIENTE/SET_PACIENTE_DATA';
export const CAT_PACIENTE_ERROR = '1/PACIENTE/CAT_PACIENTE_ERROR';
export const CAT_PACIENTE_SOLICITA = '1/PACIENTE/CAT_PACIENTE_SOLICITA';
export const CAT_PACIENTE_ES_NUEVO = '1/PACIENTE/CAT_PACIENTE_ES_NUEVO';
//DATOS ASEGURADORAS
export const SET_ASEGURADORA_DATA = 'ASEGURADORA/SET_ASEGURADORA_DATA';
export const CAT_ASEGURADORA_SELECCIONADA = 'ASEGURADORA/CAT_ASEGURADORA_SELECCIONADA';
export const SET_ASEGURADORA_RESTRICCION = 'ASEGURADORA/SET_ASEGURADORA_RESTRICCION';
export const CAT_ASEGURADORA_ERROR = 'ASEGURADORA/CAT_ASEGURADORA_ERROR';

// PLANES
export const CAT_PLANES_SOLICITA = 'PLAN/CAT_PLANES_SOLICITA';
export const SET_PLAN_DATA = 'PLAN/SET_PLAN_DATA';
export const CAT_PLANES_ERROR = 'PLAN/CAT_PLANES_ERROR';
export const CAT_PLAN_SELECCIONADO = 'PLAN/CAT_PLAN_SELECCIONADO';
export const CAT_DEPENDIENTE_SELECCIONADO = 'PLAN/CAT_DEPENDIENTE_SELECCIONADO';

//DATOS AGENDAMIENTO
export const SET_PACIENTE_AGENDAMIENTO = 'AGENDAMIENTO_FINAL/SET_PACIENTE_AGENDAMIENTO';
export const SET_ASEGURADORA_AGENDAMIENTO = 'AGENDAMIENTO_FINAL/SET_ASEGURADORA_AGENDAMIENTO';
export const SET_AGENDA_AGENDAMIENTO = 'AGENDAMIENTO_FINAL/SET_AGENDA_AGENDAMIENTO';
export const SET_DATOS_ADICIONALES_AGENDAMIENTO = 'AGENDAMIENTO_FINAL/SET_DATOS_ADICIONALES_AGENDAMIENTO';
export const SET_SUCURSAL_AGENDAMIENTO = 'AGENDAMIENTO_FINAL/SET_SUCURSAL_AGENDAMIENTO';
export const SET_DATOS_FACTURACION_AGENDAMIENTO = 'AGENDAMIENTO_FINAL/SET_DATOS_FACTURACION_AGENDAMIENTO';
export const SET_PUEDE_AGENDAR = 'AGENDAMIENTO_FINAL/SET_PUEDE_AGENDAR';

// DATOS AGENDA
export const CAT_AGENDA_SOLICITA = 'AGENDA/CAT_AGENDA_SOLICITA';
export const SET_TIPO_DATA = 'AGENDA/SET_TIPO_DATA';
export const SET_ESP_DATA = 'AGENDA/SET_ESP_DATA';
export const SET_ESP_SELECIONADA = 'USUARIO/SET_ESP_SELECIONADA';
export const SET_DR_DATA = 'AGENDA/SET_DR_DATA';
export const SET_DR_SELECCIONADO = 'USUARIO/SET_DR_SELECCIONADO';
export const SET_CM_DATA = 'AGENDA/SET_CM_DATA';
export const SET_CM_SELECCIONADO = 'USUARIO/SET_CM_SELECCIONADO';
export const SET_HORARIOS_DATA = 'AGENDA/SET_HORARIOS_DATA';
export const CAT_AGENDA_ERROR = 'AGENDA/CAT_AGENDA_ERROR';
export const SET_ERROR_AGENDA = 'AGENDA/SET_ERROR_AGENDA';
export const RESET_COMBOS = 'AGENDA/RESET_COMBOS';
export const SET_HORARIO_SELECCIONADO = 'USUARIO/SET_HORARIO_SELECCIONADO';
export const SET_HORARIO_SELECCIONADO_TEMPORAL = 'USUARIO/SET_HORARIO_SELECCIONADO_TEMPORAL';
export const SET_AUTORIZACION_HUMANA = 'AUT/SET_AUTORIZACION_HUMANA';

// DATOS ADICIONALES
export const SET_COMENTARIO = 'DATOS_ADICIONALES/SET_COMENTARIO';

// EXPANSION ACCORDION
export const SET_ACCORDION_STATE = 'ACCORDION/SET_ACCORDION_STATE';

// CARGANDO
export const SET_MOSTRAR_CARGANDO = 'CARGADOR/SET_MOSTRAR_CARGANDO';
export const SET_OCULTAR_CARGANDO = 'CARGADOR/SET_OCULTAR_CARGANDO';

// FILTROS AGENDA
export const SET_AUMENTAR_FILTRO = 'FILTROS/SET_AUMENTAR_FILTRO';
export const SET_ELIMINAR_FILTRO = 'FILTROS/SET_ELIMINAR_FILTRO';

// LIMPIAR TODO
export const SET_LIMPIAR_DATOS_AGENDAMIENTO = 'FINAL_FINAL/SET_LIMPIAR_DATOS_AGENDAMIENTO';
export const SET_LIMPIAR_DATOS_PACIENTE = 'FINAL_FINAL/SET_LIMPIAR_DATOS_PACIENTE';
export const SET_LIMPIAR_DATOS_AGENDA = 'FINAL_FINAL/SET_LIMPIAR_DATOS_AGENDA';
export const SET_LIMPIAR_DATOS_ASEGURADORA = 'FINAL_FINAL/SET_LIMPIAR_DATOS_ASEGURADORA';
export const SET_LIMPIAR_DATOS_ADICIONALES = 'FINAL_FINAL/SET_LIMPIAR_DATOS_ADICIONALES';

// TELEMEDINA REPORTE
export const CAT_TELEMEDICINA_SOLICITA = 'TELEMEDICINA/CAT_TELEMEDICINA_SOLICITA';
export const SET_TELEMEDICINA_DATA = 'TELEMEDICINA/SET_TELEMEDICINA_DATA';
export const SET_FILTROS_TELEMEDICINA = 'TELEMEDICINA/SET_FILTROS_TELEMEDICINA';
export const CAT_TELEMEDICINA_ERROR = 'TELEMEDICINA/CAT_TELEMEDICINA_ERROR';
export const SET_LIMPIAR_TELEMEDICINA = 'TELEMEDICINA/SET_LIMPIAR_TELEMEDICINA';

//DATOS VITALITY
export const CAT_VITALITY_SOLICITA = 'VITALITY/CAT_VITALITY_SOLICITA';
export const SET_VITALITY_DATA = 'VITALITY/SET_VITALITY_DATA';
export const CAT_VITALITY_ERROR = 'VITALITY/CAT_VITALITY_ERROR';

export const CAT_EXAMEN_SOLICITA = 'VITALITY/CAT_EXAMEN_SOLICITA';
export const SET_EXAMEN_DATA = 'VITALITY/SET_EXAMEN_DATA';
export const CAT_EXAMEN_ERROR = 'VITALITY/CAT_EXAMEN_ERROR';

export const CAT_RESULTADOS_SOLICITA = 'VITALITY/CAT_RESULTADOS_SOLICITA';
export const SET_RESULTADOS_DATA = 'VITALITY/SET_RESULTADOS_DATA';
export const CAT_RESULTADOS_ERROR = 'VITALITY/CAT_RESULTADOS_ERROR';
export const CAT_VITALITY_LIMPIAR = 'VITALITY/CAT_VITALITY_LIMPIAR';
export const SET_ESTADO = 'VITALITY/SET_ESTADO';

//DATOS PRODUBANCO
export const CAT_PRODUBANCO_SOLICITA = 'PRODUBANCO/CAT_PRODUBANCO_SOLICITA';
export const SET_PRODUBANCO_DATA = 'PRODUBANCO/SET_PRODUBANCO_DATA';
export const CAT_PRODUBANCO_ERROR = 'PRODUBANCO/CAT_PRODUBANCO_ERROR';
export const CAT_RESULTADOS_PRODU_SOLICITA = 'PRODUBANCO/CAT_RESULTADOS_PRODU_SOLICITA';
export const SET_RESULTADOS_PRODU_DATA = 'PRODUBANCO/SET_RESULTADOS_PRODU_DATA';
export const CAT_RESULTADOS_PRODU_ERROR = 'PRODUBANCO/CAT_RESULTADOS_PRODU_ERROR';
export const CAT_PRODUBANCO_LIMPIAR = 'PRODUBANCO/CAT_PRODUBANCO_LIMPIAR';
export const SET_ESTADO_PRODUBANCO = 'PRODUBANCO/SET_ESTADO_PRODUBANCO';
