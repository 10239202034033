import {
  SET_PACIENTE_AGENDAMIENTO,
  SET_ASEGURADORA_AGENDAMIENTO,
  SET_AGENDA_AGENDAMIENTO,
  SET_LIMPIAR_DATOS_AGENDAMIENTO,
  SET_PUEDE_AGENDAR,
} from '../../actionTypes/ActionTypes';

const INIT_STATE = {
  agenda: [],
  paciente: {}, // OK
  datosAdicionales: [],
  datosSeguro: [],
  sucursal: [],
  datosCobro: [],
  sePuedeAgendar: false,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_PACIENTE_AGENDAMIENTO: {
      return { ...state, paciente: action.payload };
    }
    case SET_PUEDE_AGENDAR: {
      return { ...state, sePuedeAgendar: action.payload };
    }
    case SET_ASEGURADORA_AGENDAMIENTO: {
      return { ...state, datosSeguro: action.payload };
    }
    case SET_AGENDA_AGENDAMIENTO: {
      return { ...state, agenda: action.payload };
    }
    case SET_LIMPIAR_DATOS_AGENDAMIENTO: {
      return { ...INIT_STATE };
    }
    default:
      return state;
  }
};
